import { MODAL_TYPES } from '~/config/constant'

export default () => {
  const { $bvModal } = useNuxtApp()
  const route = useRoute()

  const closeModal = (id: string) => $bvModal.hide(id)
  const openModal = (id: string) => $bvModal.show(id)

  const closeAllModal = () => {
    Object.values(MODAL_TYPES).forEach(closeModal)
  }

  const openModalOne = (id: string) => {
    closeAllModal()
    setTimeout(() => openModal(id), 200)
  }

  const closeExceptModal = (id: string) => {
    Object.values(MODAL_TYPES).forEach((item) => {
      if (item !== id) { closeModal(item) }
    })
  }

  const openModalWithNavigate = (id: string, type: string, openUrl?: string) => {
    const { type: _, order, ncc, ...query } = route.query
    const newQuery = { ...query, popup: type, ...(openUrl ? { openUrl } : {}) }

    navigateTo({ path: '/', query: newQuery })

    closeAllModal()
    openModal(id)
  }

  return {
    closeModal,
    openModal,
    closeAllModal,
    openModalOne,
    closeExceptModal,
    openModalWithNavigate
  }
}
